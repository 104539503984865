<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>Производ</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col>
                <search-box
                  @change="itemSelected"
                  :dialog="dialog"
                ></search-box>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col
                ><v-text-field
                  label="Шифра"
                  v-model="form.sifra"
                  :value="product.sifra"
                ></v-text-field
              ></v-col>
              <v-col cols="7"
                ><v-text-field
                  label="Баркод"
                  v-model="form.barkod"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-text-field
              label="Име на производ"
              v-model="form.ime"
            ></v-text-field>
            <v-text-field label="Состав" v-model="form.sostav"></v-text-field>
            <v-row>
              <v-col
                ><v-text-field
                  type="number"
                  label="Стара цена"
                  class="text-right"
                  min="0"
                  v-model="form.cena_stara"
                ></v-text-field
              ></v-col>
              <v-col
                ><v-text-field
                  type="number"
                  label="Нова цена"
                  class="text-right"
                  min="0"
                  v-model="form.cena_nova"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="7"
                ><v-text-field
                  type="number"
                  label="Тежина"
                  class="text-right"
                  min="0"
                  v-model="form.tezina"
                ></v-text-field
              ></v-col>
              <v-col
                ><v-select
                  label="ЕДМ"
                  :items="edmerki"
                  v-model="form.edm"
                ></v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="7"
                ><v-select
                  label="Тип"
                  :items="tip"
                  v-model="form.tip"
                ></v-select
              ></v-col>
              <v-col>
                <v-checkbox
                  v-model="form.gram_cena_flag"
                  label="Грам цена"
                  value="1"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$emit('save', form)"
            >Зачувај</v-btn
          >
          <v-btn text color="" @click="$emit('close')">Откажи</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SearchBox from "./SearchBox";
export default {
  props: {
    dialog: Boolean,
    product: Object,
  },

  watch: {
    dialog(val) {
      if (val) {
        this.form = { ...this.product };
      }
    },
  },

  data: () => ({
    form: {
      sifra: "",
      barkod: "",
      ime: "",
      sostav: "",
      cena_stara: "",
      cena_nova: "",
      tezina: "",
      edm: "",
      tip: "",
      gram_cena_flag: "",
    },
    edmerki: [
      { text: "кг.", value: "КГ" },
      { text: "мл.", value: "мл." },
      { text: "пар.", value: "КОМ" },
    ],
    tip: ["Редовен рафт", "Горни рафт", "Фрижидер"],
  }),

  components: {
    SearchBox,
  },

  methods: {
    itemSelected(item) {
      this.form = { ...item };
    },
  },
};
</script>