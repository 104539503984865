<template>
  <v-app style="overflow:hidden">
    <drawer></drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-btn
        fab
        fixed
        bottom
        right
        @click="print"
        class="no-print"
        color="primary"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
  </v-app>
</template>

<script>
import Drawer from "@/components/Drawer";
export default {
  name: "App",

  components: {
    Drawer
  },

  methods: {
    print() {
      window.print();
    },
  }
};
</script>
<style lang="scss">
.no-print {
  @media print {
    display: none !important;
  }
}

.v-main {
  @media print {
    padding: 0 !important;
  }
}
</style>
