<template>
  <v-btn-toggle borderless tile mandatory @change="changePrint" value="type">
    <v-tooltip bottom v-for="button in buttons" :key="button.name">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" :value="button.name">
          <v-icon>{{ button.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ button.title }}</span>
    </v-tooltip>
  </v-btn-toggle>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PrintToggle",
  computed: {
    ...mapGetters("printType", ["type"]),
  },
  data: () => ({
    buttons: [
      {
        name: "redoven_raft",
        title: "Редовен рафт",
        icon: "mdi-view-module",
      },
      {
        name: "goren_raft",
        title: "Горни рафт",
        icon: "mdi-grid-large",
      },
      {
        name: "frizider",
        title: "Фрижидер",
        icon: "mdi-grid",
      },
    ],
  }),

  methods: {
    ...mapActions("printType", ["setType"]),
    changePrint(value) {
      this.setType(value);
    },
  },
};
</script>