<template>
  <v-col cols="6">
    <v-row class="mala-cena">
      <v-col class="mala-cena-col">
        <div class="ime">{{ product.ime }}</div>
        <div class="sostav">{{ product.sostav }}</div>
        <div class="barkod">
          <div class="sifra">{{ product.sifra }}</div>
          {{ product.barkod }}
        </div>
      </v-col>
      <v-col class="mala-cena-col">
        <v-row v-if="akcija">
          <v-col cols="6 px-0 pb-0 mb-0">
            <div class="akcija">
              <div class="akcija-proc">-{{ akcija }}%</div>
              <div class="akcija-text">АКЦИЈА!</div>
            </div>
          </v-col>
          <v-col class="cena-stara pa-0 pr-3">
            <span>{{ Math.round(product.cena_stara) }}</span>
          </v-col>
        </v-row>
        <v-row class="mt-auto justify-end align-end">
          <v-col class="cena-nova py-0 pl-0">
            <small v-if="Number(product.tezina)"
              >{{ Number(product.tezina) }}{{ product.edm }}</small
            >
            <span>{{ Math.round(product.cena_nova) }}</span>
            <small>ден</small>
          </v-col>
        </v-row>
        <v-row v-if="gram_show && cena_gram" class="mt-0 py-0 flex-grow-0">
          <v-col class="cena-gram py-0">
            <small
              >{{ product.edm.match(/ком/gi) ? 1 : 100 }}
              {{ product.edm }} =</small
            >
            <span>{{ cena_gram }}</span>
            <small>ден</small>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Cena",
  props: {
    product: Object,
  },
  computed: {
    akcija() {
      return (
        Number(this.product.cena_stara) > Number(this.product.cena_nova) &&
        Math.round(
          ((this.product.cena_stara - this.product.cena_nova) /
            this.product.cena_stara) *
            100
        )
      );
    },
    gram_show() {
      return this.product.gram_cena_flag == "1";
    },
    cena_gram() {
      let result;
      if (this.product.tezina > 0) {
        if (this.product.edm.match(/ком/gi)) {
          result =
            Math.round(
              (Number(this.product.cena_nova) / Number(this.product.tezina)) *
                10
            ) / 10;
        } else {
          result = (
            Math.round(
              (Number(this.product.cena_nova) / Number(this.product.tezina)) *
                100 *
                10
            ) / 10
          )
            .toString()
            .replace(".", ",");
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;800&family=Libre+Barcode+EAN13+Text&family=Ubuntu:wght@400;700&display=swap");

.ime {
  font-weight: bolder;
  font-size: 1.1rem;
}

.sostav {
  font-size: 0.9rem;
}

.mala-cena {
  font-family: "Ubuntu", sans-serif;
  position: relative;
  height: 33.3333vh;
  @media print {
    border: solid rgb(87, 87, 87);
    border-width: 1px 0 0 1px;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 2px;
    background: #ff541e;
  }

  &:after {
    bottom: 2px;
    top: auto;
  }

  &-ime {
    font-weight: bold;
  }

  &-col {
    display: flex;
    flex-direction: column;
  }
}

.akcija {
  font-family: "Baloo Chettan 2", cursive;
  background: #000;
  color: #fff;
  text-align: center;

  &-proc {
    font-size: 3rem;
    font-weight: bolder;
    line-height: 3rem;
    padding: 0.2rem 0;
  }
}

.sifra {
  font-family: "Ubuntu", sans-serif;
  font-size: 0.9rem;
  line-height: 1rem;
  padding-left: 2.3rem;
}

.barkod {
  font-family: "Libre Barcode EAN13 Text", cursive;
  font-size: 5.5rem;
  line-height: 5.5rem;
  margin-top: auto;
}

.cena-stara {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 3rem;
  font-weight: bolder;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  span {
    padding: 0 7px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(-25deg);
      height: 3px;
      background: #000;
      width: 100%;
    }
  }
}

.cena-nova {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 7.5rem;
  font-weight: bolder;
  line-height: 30%;
  text-align: right;

  small {
    font-family: "Ubuntu", sans-serif;
    font-size: 1rem;
    font-weight: normal;
  }
}

.cena-gram {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 3rem;
  font-weight: bolder;
  text-align: right;
  //   line-height: 4rem;

  small {
    font-family: "Ubuntu", sans-serif;
    font-weight: normal;
    font-size: 1rem;
  }
}

.h-100 {
  height: 100% !important;
}
</style>