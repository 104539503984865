<template>
  <ceni-mali></ceni-mali>
</template>

<script>
// @ is an alias to /src

import CeniMali from "@/components/CeniMali";
export default {
  name: "Home",

  components: {
    CeniMali,
  },
};
</script>