import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products';
import printType from './modules/print-type';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    products,
    printType
  }
})
