<template>
  <v-container fluid>
    <v-row v-if="type == 'redoven_raft'">
      <cena
        v-for="product in prods"
        :key="product.sifra"
        :product="product"
      ></cena>
    </v-row>
    <v-row v-else-if="type == 'goren_raft'">
      <cena-a5
        v-for="product in prods"
        :key="product.sifra"
        :product="product"
      ></cena-a5>
    </v-row>
    <v-row v-else-if="type == 'frizider'">
      <cena-frizider
        v-for="product in prods"
        :key="product.sifra"
        :product="product"
      ></cena-frizider>
    </v-row>
  </v-container>
</template>

<script>
import Cena from "@/components/Cena.vue";
import CenaA5 from "./CenaA5.vue";
import CenaFrizider from "./CenaFrizider.vue";
import { mapGetters } from "vuex";
export default {
  name: "CeniMali",
  components: {
    Cena,
    CenaA5,
    CenaFrizider,
  },
  computed: {
    ...mapGetters("products", ["products", "filter"]),
    ...mapGetters("printType", ["type"]),
    prods() {
      const filter = this.filter;
      return this.products.filter((product) => {
        if (filter.length > 0) {
          return product.tip.match(new RegExp(filter, "gi"));
        } else {
          return true;
        }
      });
    },
  },
};
</script>
