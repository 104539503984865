<template>
  <v-card class="ma-2">
    <v-card-title style="font-size: 1rem; line-height: 1.2rem; word-break: break-word;">{{ product.ime }}</v-card-title>
    <v-card-subtitle v-if="product.sostav">{{ product.sostav }}</v-card-subtitle>
    <v-card-text>
        <div>Шифра: {{product.sifra}}</div>
        <div>Баркод: {{product.barkod}}</div>
        <div>Стара цена: {{product.cena_stara}}</div>
        <div>Нова цена: {{product.cena_nova}}</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon color="indigo" @click="$emit('edit', product)">
          <v-icon>mdi-file-edit</v-icon>
      </v-btn>
      <v-btn icon color="red" @click="$emit('remove', product)">
          <v-icon>mdi-file-remove</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Product",
  props: {
      product: Object
  }
};
</script>