<template>
  <v-col cols="6 cena-a5 d-flex flex-column">
    <v-row class="flex-grow-0">
      <div class="col ime">{{ product.ime }}</div>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col class="sostav">{{ product.sostav }}</v-col>
    </v-row>
    <v-row v-if="akcija" class="mt-auto mb-10 flex-grow-0">
      <v-col cols="5" class="pb-10 mb-5">
        <div class="akcija">
          <div class="akcija-proc">-{{ akcija }}%</div>
          <div class="akcija-text">АКЦИЈА!</div>
        </div>
      </v-col>
      <v-col class="cena-stara pa-0 pr-3">
        <span>{{ Math.round(product.cena_stara) }}</span>
      </v-col>
    </v-row>
    <v-row
      class="justify-end align-end flex-grow-0"
      :class="{ 'mt-auto': !akcija }"
    >
      <v-col
        class="cena-nova py-0 pl-0"
        :class="{ lg: product.cena_nova.toString().length < 4 }"
      >
        <small v-if="Number(product.tezina)"
          >{{ Number(product.tezina) }}{{ product.edm }}</small
        >
        <span>{{ Math.round(product.cena_nova) }}</span>
        <small>ден</small>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0">
      <v-col cols="2" class="pb-0">
        <!-- <img class="logo pa-2" src="../assets/ultra-logo.svg" alt="" /> -->
      </v-col>
      <v-col class="barkod pb-1" cols="3">
        <div class="sifra">{{ product.sifra }}</div>
        {{ product.barkod }}
      </v-col>
      <v-col
        v-if="gram_show && cena_gram"
        class="cena-gram pt-0 pb-2 justify-end align-end d-flex"
      >
        <small
          >{{ product.edm.match(/ком/gi) ? 1 : 100 }} {{ product.edm }} =</small
        >
        <span>{{ cena_gram }}</span>
        <small>ден</small>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "Cena",
  props: {
    product: Object,
  },
  computed: {
    akcija() {
      return (
        Number(this.product.cena_stara) > Number(this.product.cena_nova) &&
        Math.round(
          ((this.product.cena_stara - this.product.cena_nova) /
            this.product.cena_stara) *
            100
        )
      );
    },
    gram_show() {
      return this.product.gram_cena_flag == "1";
    },
    cena_gram() {
      let result;
      if (this.product.tezina > 0) {
        if (this.product.edm.match(/ком/gi)) {
          result =
            Math.round(
              (Number(this.product.cena_nova) / Number(this.product.tezina)) *
                10
            ) / 10;
        } else {
          result = (
            Math.round(
              (Number(this.product.cena_nova) / Number(this.product.tezina)) *
                100 *
                10
            ) / 10
          )
            .toString()
            .replace(".", ",");
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;800&family=Libre+Barcode+EAN13+Text&family=Ubuntu:wght@400;700&display=swap");

.ime {
  font-weight: 900;
  font-size: 2rem;
  text-align: center;
  line-height: 2.5rem;
}

.sostav {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}

.cena-a5 {
  position: relative;
  height: 100vh;
  border: solid #ff541e;
  border-width: 40px 0;
  font-family: "Ubuntu", sans-serif;

  @media print {
    border-right: solid 1px rgb(87, 87, 87);
  }

  &-ime {
    font-weight: bold;
  }
}

.akcija {
  font-family: "Baloo Chettan 2", cursive;
  background: #000;
  color: #fff;
  text-align: center;
  padding: 10px 0;

  &-proc {
    font-size: 3rem;
    font-weight: bolder;
    line-height: 3rem;
    padding: 0.2rem 0;
  }
}

.logo {
  background: #ff541e;
}

.sifra {
  font-family: "Ubuntu", sans-serif;
  font-size: 0.9rem;
  line-height: 1rem;
  padding-left: 2.3rem;
}

.barkod {
  font-family: "Libre Barcode EAN13 Text", cursive;
  font-size: 5.5rem;
  line-height: 5.5rem;
  margin-top: auto;
}

.cena-stara {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 3rem;
  font-weight: bolder;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  span {
    padding: 0 7px;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: rotate(-25deg);
      height: 3px;
      background: #000;
      width: 100%;
    }
  }
}

.cena-nova {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 12.5rem;
  font-weight: bolder;
  line-height: 30%;
  text-align: right;

  &.lg {
    font-size: 16rem;
  }

  small {
    font-family: "Ubuntu", sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
  }
}

.cena-gram {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: bolder;
  text-align: right;

  small {
    font-family: "Ubuntu", sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 3rem;
  }
}
</style>
