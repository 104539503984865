import {
  ADD_PRODUCT,
  SET_PRODUCTS,
  SET_PRODUCT_EDITED,
  SAVE_PRODUCT,
  DELETE_PRODUCT,
  SET_FILTER
} from "../mutation-types";

// const product_scheme = {
//   sifra: '',
//   ime: '',
//   sostav: '',
//   barkod: '',
//   cena_stara: 0,
//   cena_nova: 0,
//   tezina: 0,
//   edm: 'гр'
// };

const state = {
  products: [],
  edited: null,
  filter: ''
};

const mutations = {
  [ADD_PRODUCT](state, product) {
    state.products.push(product);
  },

  [SET_PRODUCTS](state, products) {
    //Must have required fileds
    // state.products = products;
    products.map(product => {
      if(product && product.sifra && product.sifra.length > 0) {
        // product.gram_cena_flag = Number(product.gram_cena_flag);
        if(state.products.findIndex((prod) => prod.sifra == product.sifra) == -1) {
          state.products.push(product);
        }
      }
    });
  },

  [SET_PRODUCT_EDITED](state, product) {
    state.edited = product;
  },

  [SAVE_PRODUCT](state, product) {
    const index = state.products.findIndex((prod) => prod.sifra == product.sifra);
    if (index != -1) {
      state.products.splice(index, 1, product);
    } else {
      state.products.push(product);
    }
  },
  [DELETE_PRODUCT](state, product) {
    const index = state.products.findIndex((prod) => prod.sifra == product.sifra);
    if (index != -1) {
      state.products.splice(index, 1);
    }
  },
  [SET_FILTER](state, filter) {
    state.filter = filter;
  }
};

const actions = {
  addProduct({ commit }, product) {
    commit(ADD_PRODUCT, { ...product });
  },

  saveProduct({ commit }, product) {
    commit(SAVE_PRODUCT, { ...product });
  },

  deleteProduct({ commit }, product) {
    commit(DELETE_PRODUCT, product);
  },

  setEditedProduct({ commit }, product) {
    commit(SET_PRODUCT_EDITED, { ...product });
  },

  async parseFile({ commit }, file) {
    const data = new FormData();
    data.append('excel', file);

    await fetch('https://ultra.l3.mk/api/', {
      method: 'POST',
      body: data
    })
    .then((response) => response.json())
    .then((data) => commit(SET_PRODUCTS, data));
  },

  setFilter({ commit }, filter) {
    commit(SET_FILTER, filter);
  },

  init({ commit }) {
    fetch("https://ultra.l3.mk/api/")
      .then((response) => response.json())
      .then((data) => commit(SET_PRODUCTS, data));
  },
};

const getters = {
  products: (state) => state.products,
  edited: (state) => state.edited,
  filter: (state) => state.filter,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
