import { SET_PRINT_TYPE } from "../mutation-types";

const state = {
  type: 'redoven_raft',
};

const mutations = {
  [SET_PRINT_TYPE](state, type) {
    state.type = type;
  },
};

const actions = {
  setType({ commit }, type) {
    commit(SET_PRINT_TYPE, type);
  },
};

const getters = {
  type: (state) => state.type,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
