<template>
  <v-col cols="6" class="mala-cena pb-0 d-flex flex-column">
    <div class="rotatedbc">
      <div class="barkod">{{ product.sifra }}</div>
      <span class="sifra pl-8">{{ product.sifra }}</span>
    </div>
    <!-- <v-row>
      <v-col cols="12"
        ><div class="ime">{{ product.ime }}</div></v-col
      >
    </v-row> -->
    <v-row>
      <!-- <v-col class="d-flex flex-column rotatedbc">
        <div class="barkod">{{ product.sifra }}</div>
        <span class="sifra pl-9">{{product.sifra }}</span>
      </v-col> -->
      <v-col>
        <div class="ime">{{ product.ime }}</div>
      </v-col>
      <v-col class="d-flex flex-column">
        <div class="cena-nova mt-auto mb-9">
          <span>{{ Math.round(product.cena_nova) }}</span>
          <small>ден</small>
        </div>
        <div v-if="gram_show && cena_gram" class="cena-gram mb-3">
          <small
            >{{ product.edm.match(/ком/gi) ? 1 : 100 }} {{ product.edm }} =
          </small>
          <span>{{ cena_gram }}</span>
          <small> ден</small>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "CenaFrizider",
  props: {
    product: Object,
  },
  computed: {
    akcija() {
      return (
        Number(this.product.cena_stara) > Number(this.product.cena_nova) &&
        Math.round(
          ((this.product.cena_stara - this.product.cena_nova) /
            this.product.cena_stara) *
            100
        )
      );
    },
    gram_show() {
      return this.product.gram_cena_flag == "1";
    },
    cena_gram() {
      let result;
      if (this.product.tezina > 0) {
        if (this.product.edm.match(/ком/gi)) {
          result =
            Math.round(
              (Number(this.product.cena_nova) / Number(this.product.tezina)) *
                10
            ) / 10;
        } else {
          result = (
            Math.round(
              (Number(this.product.cena_nova) / Number(this.product.tezina)) *
                100 *
                10
            ) / 10
          )
            .toString()
            .replace(".", ",");
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@400;800&family=Libre+Barcode+39&family=Ubuntu:wght@400;700&display=swap");

.ime {
  font-weight: 400;
  font-size: 1rem;
  padding-left: 3.2rem;
}

.sostav {
  font-size: 0.9rem;
}

.mala-cena {
  font-family: "Ubuntu", sans-serif;
  position: relative;
  height: 37.1mm;
  overflow: hidden;

  @media print {
    height: 12.5vh;
    border: solid rgb(87, 87, 87);
    border-width: 1px 0 0 1px;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 2px;
    background: #ff541e;
  }

  &:after {
    bottom: 2px;
    top: auto;
  }

  &-ime {
    font-weight: bold;
  }
}

.barkod {
  font-family: "Libre Barcode 39", cursive;
  font-size: 2.5rem;
  margin-top: auto;
  line-height: 0;
}

.cena-nova {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 5rem;
  line-height: 0;
  font-weight: bolder;
  //   line-height: 30%;
  text-align: right;

  small {
    font-family: "Ubuntu", sans-serif;
    font-size: 1rem;
    font-weight: normal;
  }
}

.cena-gram {
  font-family: "Baloo Chettan 2", cursive;
  font-size: 2rem;
  line-height: 0;
  padding: 0.5rem 0;
  font-weight: bolder;
  text-align: right;
  //   line-height: 4rem;

  small {
    font-family: "Ubuntu", sans-serif;
    font-weight: normal;
    font-size: 1rem;
  }
}

.h-100 {
  height: 100% !important;
}

.rotatedbc {
  position: absolute;
  left: 2rem;
  top: 0.75rem;
  transform: rotate(90deg);
  transform-origin: left top;
}
</style>
