<template>
  <v-navigation-drawer permanent width="300" class="no-print" app>
    <v-list-item>
      <v-list-item-content>
        <v-row>
          <v-col>
            <v-btn
              block
              elevation="0"
              @click="uploadClick"
              color="primary"
              :loading="loading"
            >
              Увоз
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block elevation="0" color="" @click="createProduct">
              Нов
            </v-btn>
            <input
              type="file"
              name="excel"
              @change="fileUpload"
              ref="productsFile"
              style="display: none"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item class="justify-center mt-5">
      <print-toggle></print-toggle>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-select
          label="Приказ"
          :items="filters"
          v-model="filter"
          @change="changeFilter"
        ></v-select>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-text-field
          label="Најди"
          prepend-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </v-list-item-content>
    </v-list-item>
    <product
      v-for="product in prods.slice(0, 10)"
      :key="product.sifra"
      :product="product"
      @remove="removeProduct"
      @edit="editProduct"
    ></product>
    <product-dialog
      :dialog="dialog"
      :product="edited"
      @save="save"
      @close="dialog = false"
    ></product-dialog>
  </v-navigation-drawer>
</template>
<script>
import Product from "@/components/Product";
import PrintToggle from "@/components/PrintToggle";
import ProductDialog from "@/components/ProductDialog";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Drawer",
  data: () => ({
    search: "",
    filter: "",
    filters: [
      { text: "Сите", value: "" },
      { text: "Редовен рафт", value: "Редовен рафт" },
      { text: "Горни рафт", value: "Горни рафт" },
      { text: "Фрижидер", value: "Фрижидер" },
    ],
    dialog: false,
    loading: false,
    productScheme: {
      sifra: "",
      barkod: "",
      ime: "",
      sostav: "",
      cena_stara: "",
      cena_nova: "",
      tezina: "",
      edm: "",
      tip: "",
      gram_cena_flag: "",
    },
  }),
  computed: {
    ...mapGetters("products", ["products", "edited"]),
    prods() {
      return this.products.filter((product) => {
        if (this.search.length > 0) {
          if (product.ime && product.sifra && product.barkod) {
            let search = new RegExp(this.search, "gi");
            return (
              product.ime.search(search) != -1 ||
              product.sifra.search(search) != -1 ||
              product.barkod.search(search) != -1
            );
          }
        } else {
          return true;
        }
      });
    },
  },

  created() {
    this.setEditedProduct(this.productScheme);
  },

  components: {
    PrintToggle,
    Product,
    ProductDialog,
  },
  methods: {
    ...mapActions("products", [
      "setEditedProduct",
      "saveProduct",
      "deleteProduct",
      "parseFile",
      "setFilter",
    ]),
    uploadClick() {
      this.$refs.productsFile.click();
    },

    save(product) {
      this.saveProduct(product);
      this.dialog = false;
    },

    createProduct() {
      this.setEditedProduct(this.productScheme);
      this.dialog = true;
    },

    removeProduct(product) {
      if (confirm("Дали сте сигурни дека сакате да го избришете производот?")) {
        this.deleteProduct(product);
      }
    },

    editProduct(product) {
      this.setEditedProduct(product);
      this.dialog = true;
    },

    async fileUpload() {
      this.loading = true;
      const input = this.$refs.productsFile;
      await this.parseFile(input.files[0]);
      this.loading = false;
    },

    changeFilter() {
      this.setFilter(this.filter);
    },
  },
};
</script>
