<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    item-text="ime"
    item-value="sifra"
    label="Најди производ"
    prepend-icon="mdi-database-search"
    return-object
    :filter="filter"
    @change="$emit('change', model)"
  ></v-autocomplete>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
  },

  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),

  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const ime =
          entry.ime.length > this.descriptionLimit
            ? entry.ime.slice(0, this.descriptionLimit) + "..."
            : entry.ime;

        return Object.assign({}, entry, { ime });
      });
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.model = null;
      }
    },

    search() {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      fetch("https://ultra.l3.mk/api/")
        .then((res) => res.json())
        .then((res) => {
          this.count = res.length;
          this.entries = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },

  methods: {
    filter(item, queryText) {
      return (
        Number(item.sifra) == Number(queryText) ||
        item.barkod == queryText ||
        item.ime.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>